import React, { useState } from 'react';
import { default as UI } from '@/common/constants/ui';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, Grid, Typography, Button, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { InsertDriveFileOutlined } from '@material-ui/icons';
import { MfpSettingContainer } from './MfpSettingContainer';
import DialogConfirmChanges from './DialogConfirmChanges';
import { default as mfpSettingImg } from '@/common/img/mfp-setting/index';

const styles = (theme: Theme) =>
    createStyles({
        title: {
            fontSize: 14,
            lineHeight: '20px',
            color: '#666666',
            marginBottom: 15,
        },
        titleFormatFile: {
            fontSize: 14,
            lineHeight: '20px',
            color: '#666666',
            marginBottom: 5,
        },
        buttonFirst: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 6,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: 44,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonMiddle: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 0,
            height: 44,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonLast: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 6,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: 44,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonSingle: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 6,
            height: 44,
            margin: 0,
            marginTop: -10,
            marginBottom: 20,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonSelected: {
            backgroundColor: `${theme.palette.secondary.main}`,
            color: '#ffffff',
        },
        formLabel: {
            textAlign: 'left',
            height: 37,
        },
        iconRotate180: {
            transform: 'scale3d(1, -1, -1)',
            marginLeft: 3,
        },
        iconRotate90: {
            transform: 'rotate(90deg)',
            marginLeft: 3,
        },
        confirmButton: {
            marginRight: theme.spacing.unit * 2,
            '&& button': {
                textTransform: 'unset',
            },
        },
        yesBtn: {
            color: '#ff7300',
            border: 'none',
        },
    });

interface Props extends WithStyles<typeof styles> {
    skipEffect?: boolean;
}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const mfpSettingContainer = MfpSettingContainer.useContainer();

    const container = Object.assign(mfpSettingContainer);
    const outputType = (
        <>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <Typography className={classes.titleFormatFile}>{locale.t(locale.keys.mfpDefaultSetting.title.single)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            value={
                                mfpSettingContainer.defaultSetting.scanAttributeData.outputType === undefined
                                    ? mfpSettingContainer.defaultSetting.scanAttributeData.outputType
                                    : mfpSettingContainer.defaultSetting.scanAttributeData.outputType.toString()
                            }
                            onChange={mfpSettingContainer.handleChangeTypeFormatFile}
                            name={'outputType'}
                        >
                            <FormControlLabel
                                className={classes.formLabel}
                                value="0"
                                control={<Radio />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.tiffJpeg)}
                                disabled={mfpSettingContainer.disabledOutputType0() || mfpSettingContainer.defaultSetting.scanAttributeData.verticalJudge}
                            />
                            <FormControlLabel className={classes.formLabel} value="1" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.pdf)} />
                            <FormControlLabel
                                className={classes.formLabel}
                                value="9"
                                control={<Radio />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.clearlightPdf)}
                                disabled={mfpSettingContainer.disabledOutputType910() || mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage || mfpSettingContainer.defaultSetting.scanAttributeData.verticalJudge}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <Typography className={classes.titleFormatFile}>{locale.t(locale.keys.mfpDefaultSetting.title.multi)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            value={
                                mfpSettingContainer.defaultSetting.scanAttributeData.outputType === undefined
                                    ? mfpSettingContainer.defaultSetting.scanAttributeData.outputType
                                    : mfpSettingContainer.defaultSetting.scanAttributeData.outputType.toString()
                            }
                            onChange={mfpSettingContainer.handleChangeTypeFormatFile}
                            name={'outputType'}
                        >
                            <FormControlLabel
                                className={classes.formLabel}
                                value="2"
                                control={<Radio />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.tiff)}
                                disabled={mfpSettingContainer.disabledOutputType2() || mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage || mfpSettingContainer.defaultSetting.scanAttributeData.verticalJudge}
                            />
                            <FormControlLabel className={classes.formLabel} value="3" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.pdf)} />
                            <FormControlLabel
                                className={classes.formLabel}
                                value="10"
                                control={<Radio />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.clearlightPdf)}
                                disabled={mfpSettingContainer.disabledOutputType910() || mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage || mfpSettingContainer.defaultSetting.scanAttributeData.verticalJudge}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );

    const rotate = (
        <>
            <Button
                className={classes.buttonFirst}
                style={{ minWidth: 140 }}
                variant="contained"
                classes={{ root: !mfpSettingContainer.defaultSetting.scanAttributeData.rotate ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChoosePaperOrientation(false)}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.textOrientation)}
            </Button>
            <Button
                className={classes.buttonLast}
                style={{ minWidth: 140 }}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.rotate ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChoosePaperOrientation(true)}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.rotate90DegLeft)}
            </Button>
        </>
    );

    const paperSide = (
        <>
            <Button
                className={classes.buttonFirst}
                variant="contained"
                classes={{ root: !mfpSettingContainer.defaultSetting.scanAttributeData.scanBothSide ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChoosePaperSide(false)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.oneSide)}
            </Button>
            <Button
                className={classes.buttonMiddle}
                variant="contained"
                classes={{
                    root:
                        mfpSettingContainer.defaultSetting.scanAttributeData.scanBothSide && mfpSettingContainer.defaultSetting.scanAttributeData.clippedVertical ? classes.buttonSelected : undefined,
                }}
                onClick={() => mfpSettingContainer.handleClickChoosePaperSide(true, true)}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.twosideRightleft)}
            </Button>
            <Button
                className={classes.buttonLast}
                variant="contained"
                classes={{
                    root:
                        mfpSettingContainer.defaultSetting.scanAttributeData.scanBothSide && !mfpSettingContainer.defaultSetting.scanAttributeData.clippedVertical ? classes.buttonSelected : undefined,
                }}
                onClick={() => mfpSettingContainer.handleClickChoosePaperSide(true, false)}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.twoSideUpDown)}
            </Button>
        </>
    );

    const splitPaperFeeding = (
        <>
            <Button
                className={classes.buttonFirst}
                variant="contained"
                classes={{
                    root: !mfpSettingContainer.defaultSetting.scanAttributeData.batch && !mfpSettingContainer.defaultSetting.scanAttributeData.sadf ? classes.buttonSelected : undefined,
                }}
                onClick={() => mfpSettingContainer.handleClickChooseSplitPaperFeeding(false, false)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.no)}
            </Button>
            <Button
                className={classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.batch ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseSplitPaperFeeding(true, false)}
                disabled={mfpSettingContainer.disabledSplitPaperFeeding() || mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.severalPagesDocs)}
            </Button>
            <Button
                className={classes.buttonLast}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.sadf ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseSplitPaperFeeding(false, true)}
                disabled={mfpSettingContainer.disabledSplitPaperFeeding() || mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.sadf)}
            </Button>
        </>
    );

    const mixedSize = (
        <>
            <Button
                className={classes.buttonFirst}
                variant="contained"
                classes={{
                    root: mfpSettingContainer.defaultSetting.scanAttributeData.size === 0 && !mfpSettingContainer.defaultSetting.scanAttributeData.mixedSize ? classes.buttonSelected : undefined,
                }}
                onClick={() => {
                    mfpSettingContainer.handleClickChooseAutoDetection(false);
                }}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.sameSize)}
            </Button>
            <Button
                className={classes.buttonLast}
                variant="contained"
                classes={{
                    root: mfpSettingContainer.defaultSetting.scanAttributeData.size === 0 && mfpSettingContainer.defaultSetting.scanAttributeData.mixedSize ? classes.buttonSelected : undefined,
                }}
                onClick={() => {
                    mfpSettingContainer.handleClickChooseAutoDetection(true);
                }}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.mixedSize)}
            </Button>
        </>
    );

    const mixedSizeMode = (
        <>
            <Button
                className={classes.buttonFirst}
                variant="contained"
                classes={{
                    root: mfpSettingContainer.defaultSetting.scanAttributeData.mixedSizeMode === 0 ? classes.buttonSelected : undefined,
                }}
                onClick={() => {
                    mfpSettingContainer.handleClickChooseMixedSizeMode(0);
                }}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.size !== 0 || !mfpSettingContainer.defaultSetting.scanAttributeData.mixedSize}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.mixedSizeSame)}
                <img src={mfpSettingImg.mfpSetting.mixedSizeModeSame} alt="img" style={{ width: 25, marginInlineStart: 8 }} />
            </Button>
            <Button
                className={classes.buttonLast}
                variant="contained"
                classes={{
                    root: mfpSettingContainer.defaultSetting.scanAttributeData.mixedSizeMode === 1 ? classes.buttonSelected : undefined,
                }}
                onClick={() => {
                    mfpSettingContainer.handleClickChooseMixedSizeMode(1);
                }}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.size !== 0 || !mfpSettingContainer.defaultSetting.scanAttributeData.mixedSize}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.mixedSizeDiff)}
                <img src={mfpSettingImg.mfpSetting.mixedSizeModeDiff} alt="img" style={{ width: 25, marginInlineStart: 8 }} />
            </Button>
        </>
    );

    const aggregation = (
        <>
            <Button
                className={classes.buttonFirst}
                variant="contained"
                classes={{ root: !mfpSettingContainer.defaultSetting.scanAttributeData.aggregation ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseSummary(false)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.noSummary)}
            </Button>
            <Button
                className={classes.buttonLast}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.aggregation ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseSummary(true)}
                disabled={mfpSettingContainer.disabledAggregation1() || mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.summarizeEach4sheets)}
            </Button>
        </>
    );

    const mixedSizeIndefinite = (
        <>
            <Button
                className={classes.buttonFirst}
                variant="contained"
                classes={{
                    root: mfpSettingContainer.defaultSetting.scanAttributeData.size === 15 && !mfpSettingContainer.defaultSetting.scanAttributeData.mixedSize ? classes.buttonSelected : undefined,
                }}
                onClick={() => {
                    mfpSettingContainer.handleClickChooseAutoDetection(false, true);
                }}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.sameSize)}
            </Button>
            <Button
                className={classes.buttonLast}
                variant="contained"
                classes={{
                    root: mfpSettingContainer.defaultSetting.scanAttributeData.size === 15 && mfpSettingContainer.defaultSetting.scanAttributeData.mixedSize ? classes.buttonSelected : undefined,
                }}
                onClick={() => {
                    mfpSettingContainer.handleClickChooseAutoDetection(true, true);
                }}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.mixedSize)}
            </Button>
        </>
    );

    const getLabelPaperSize = (i: number) => {
        switch (i) {
            case 1:
                return (
                    <>
                        <span>{locale.t(locale.keys.mfpDefaultSetting.switchButton.a3)}</span> <InsertDriveFileOutlined className={classes.iconRotate90} />
                    </>
                );
            case 2:
                return (
                    <>
                        <span>{locale.t(locale.keys.mfpDefaultSetting.switchButton.b4)}</span> <InsertDriveFileOutlined className={classes.iconRotate90} />
                    </>
                );
            case 3:
                return (
                    <>
                        <span>{locale.t(locale.keys.mfpDefaultSetting.switchButton.a4)}</span> <InsertDriveFileOutlined className={classes.iconRotate180} />
                    </>
                );
            case 4:
                return (
                    <>
                        <span>{locale.t(locale.keys.mfpDefaultSetting.switchButton.a4)}</span> <InsertDriveFileOutlined className={classes.iconRotate90} />
                    </>
                );
            case 5:
                return (
                    <>
                        <span>{locale.t(locale.keys.mfpDefaultSetting.switchButton.b5)}</span> <InsertDriveFileOutlined className={classes.iconRotate180} />
                    </>
                );
            case 6:
                return (
                    <>
                        <span>{locale.t(locale.keys.mfpDefaultSetting.switchButton.b5)}</span> <InsertDriveFileOutlined className={classes.iconRotate90} />
                    </>
                );
        }
    };

    const paperSize = [];
    for (let i = 1; i <= 6; i += 1) {
        paperSize.push(
            <Button
                key={i}
                className={i === 1 ? classes.buttonFirst : i === 6 ? classes.buttonLast : classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.size === i ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChoosePaperSize(i)}
            >
                {getLabelPaperSize(i)}
            </Button>,
        );
    }
    const p1 = container.defaultSetting.scanAttributeData.preset1Horizontal * container.defaultSetting.scanAttributeData.preset1Vertical;
    const p2 = container.defaultSetting.scanAttributeData.preset2Horizontal * container.defaultSetting.scanAttributeData.preset2Vertical;
    const p3 = container.defaultSetting.scanAttributeData.preset3Horizontal * container.defaultSetting.scanAttributeData.preset3Vertical;
    const undefinedSize = (
        <>
            {p1 > 0 && (
                <Button
                    className={p2 + p3 > 0 ? classes.buttonFirst : classes.buttonSingle}
                    style={{ width: 132, padding: 0 }}
                    variant="contained"
                    classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.size === 7 ? classes.buttonSelected : undefined }}
                    onClick={() => {
                        mfpSettingContainer.handleClickChoosePaperSize(7);
                    }}
                >
                    {container.defaultSetting.scanAttributeData.preset1Horizontal}mm x {container.defaultSetting.scanAttributeData.preset1Vertical}mm
                </Button>
            )}
            {p2 > 0 && (
                <Button
                    className={p1 > 0 && p3 > 0 ? classes.buttonMiddle : p1 + p3 === 0 ? classes.buttonSingle : p3 > 0 ? classes.buttonFirst : classes.buttonLast}
                    style={{ width: 132, padding: 0 }}
                    variant="contained"
                    classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.size === 8 ? classes.buttonSelected : undefined }}
                    onClick={() => {
                        mfpSettingContainer.handleClickChoosePaperSize(8);
                    }}
                >
                    {container.defaultSetting.scanAttributeData.preset2Horizontal}mm x {container.defaultSetting.scanAttributeData.preset2Vertical}mm
                </Button>
            )}
            {p3 > 0 && (
                <Button
                    className={p1 + p2 > 0 ? classes.buttonLast : classes.buttonSingle}
                    style={{ width: 132, padding: 0 }}
                    variant="contained"
                    classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.size === 9 ? classes.buttonSelected : undefined }}
                    onClick={() => {
                        mfpSettingContainer.handleClickChoosePaperSize(9);
                    }}
                >
                    {container.defaultSetting.scanAttributeData.preset3Horizontal}mm x {container.defaultSetting.scanAttributeData.preset3Vertical}mm
                </Button>
            )}
        </>
    );

    const DocumentThickness = (
        <>
            <Button
                className={classes.buttonFirst}
                style={{ width: 80, padding: 0 }}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.documentThickness === 1 ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickDocumentThickness(1)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.thin)}
            </Button>
            <Button
                className={classes.buttonMiddle}
                style={{ width: 80, padding: 0 }}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.documentThickness === 0 ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickDocumentThickness(0)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.normal)}
            </Button>
            <Button
                className={classes.buttonLast}
                style={{ width: 80, padding: 0 }}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.documentThickness === 2 ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickDocumentThickness(2)}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.thick)}
            </Button>
        </>
    );

    const LargerScan = (
        <>
            <Button
                className={classes.buttonFirst}
                style={{ width: 80, padding: 0 }}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.largerScan === false ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickLargerScan(false)}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.size !== 7 && mfpSettingContainer.defaultSetting.scanAttributeData.size !== 8 && mfpSettingContainer.defaultSetting.scanAttributeData.size !== 9 && mfpSettingContainer.defaultSetting.scanAttributeData.size !== 14}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.none)}
            </Button>
            <Button
                className={classes.buttonLast}
                style={{ width: 80, padding: 0 }}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.largerScan === true ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickLargerScan(true)}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.size !== 7 && mfpSettingContainer.defaultSetting.scanAttributeData.size !== 8 && mfpSettingContainer.defaultSetting.scanAttributeData.size !== 9 && mfpSettingContainer.defaultSetting.scanAttributeData.size !== 14}
            >
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.do)}
            </Button>
        </>
    );

    return (
        <>
            {mfpSettingContainer.ui === UI.state.Error && <div data-testid={UI.state.Error}>Error</div>}
            {(mfpSettingContainer.ui === UI.state.Loaded || mfpSettingContainer.ui === UI.state.Loading || props.skipEffect) && (
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item md={6} container direction="column" justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.formatFile)}</Typography>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {outputType}
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 15 }}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.paperOrientation)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {rotate}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.paperSide)}</Typography>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {paperSide}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.splitPaperFeeding)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {splitPaperFeeding}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.documentThickness)}</Typography>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {DocumentThickness}
                        </Grid>
                    </Grid>

                    <Grid item md={6} container direction="column" justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.automaticDetection)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {mixedSize}
                        </Grid>
                        <>
                            <Grid item xs={12}>
                                <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.mixedSizeMode)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {mixedSizeMode}
                            </Grid>
                        </>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.paperSize)}</Typography>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {paperSize}
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                className={classes.buttonSingle}
                                variant="contained"
                                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.size === 14 ? classes.buttonSelected : undefined }}
                                onClick={() => mfpSettingContainer.handleClickChoosePaperSize(14)}
                            >
                                {locale.t(locale.keys.mfpDefaultSetting.switchButton.businessCard)} <InsertDriveFileOutlined className={classes.iconRotate90} />
                            </Button>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {aggregation}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.undefined)}</Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {mixedSizeIndefinite}
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {undefinedSize}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.largerScan)}</Typography>
                        </Grid>
                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {LargerScan}
                        </Grid>
                    </Grid>
                    <DialogConfirmChanges callBack={mfpSettingContainer.dialogObject.functionHandleConfirmChanges} />
                </Grid>
            )}
        </>
    );
};

export default withStyles(styles)(Component);
