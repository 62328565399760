import { default as React } from 'react';
import { default as UI } from '@/common/constants/ui';
import locale from '@/common/utils/locale';
import { Theme, createStyles, WithStyles, withStyles, Grid, Typography, Button, TextField, Switch, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox, Tooltip } from '@material-ui/core';
import { MfpSettingContainer } from './MfpSettingContainer';
import { default as eraseBorderImg } from '@/common/img/endpoint-default-setting/index';
import DialogConfirmChanges from './DialogConfirmChanges';
import { Help } from '@material-ui/icons';

const styles = (theme: Theme) =>
    createStyles({
        gridFullWidth: {
            width: '100%',
            paddingRight: 40,
        },
        title: {
            fontSize: 14,
            lineHeight: '20px',
            color: '#666666',
        },
        resolutionContent: {
            marginTop: theme.spacing.unit,
        },
        textFieldErase: {
            height: 66,
            paddingRight: 10,
        },
        input: {
            paddingLeft: 10,
        },
        textField: {
            width: '100%',
            height: 66,
            marginBottom: 15,
            borderRadius: 4,
            backgroundColor: '#eeeeee',
        },
        textFieldFormSize: {
            width: '45%',
            height: 66,
            marginBottom: 15,
            borderRadius: 4,
            backgroundColor: '#eeeeee',
        },
        clearIcon: {
            width: '10%',
            height: 12,
        },
        iconCircle: {
            width: 16,
            height: 16,
        },
        iconToggle: {
            marginRight: 30,
        },
        buttonFirst: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 6,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: 44,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonMiddle: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 0,
            height: 44,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonLast: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: 6,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: 44,
            margin: 0,
            marginBottom: 30,
            textTransform: 'none',
            fontSize: 14,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
        },
        buttonSelected: {
            backgroundColor: '#ff7300',
        },
        buttonSingle: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: `${theme.palette.secondary.main}`,
            borderRadius: 6,
            margin: '0 6px',
            fontSize: 24,
            lineHeight: '28px',
            marginTop: '20px',
            minWidth: 44,
            minHeight: 32,
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ff7300',
                    backgroundColor: '#ffffff',
                },
            },
            '&:disabled': {
                color: '#ffffff',
                border: `1px solid #bdbdbd`,
            },
        },
        formLabel: {
            textAlign: 'left',
            height: 37,
        },
        radioGroup: {
            width: '100%',
        },
        formLabelErase: {
            textAlign: 'left',
            width: '50%',
        },
        formControlLabel: {
            marginTop: '20px',
        },
        eraseBorderImg: {
            width: 120,
            height: 66,
            marginTop: -15,
            paddingRight: 10,
        },
        erase: {
            marginTop: theme.spacing.unit * 2,
        },
        gray2: {
            width: '36px',
            height: '36px',
            borderRadius: '6px',
            border: 'solid 1px #525252',
            backgroundColor: '#eaeaea',
            minWidth: 'auto',
            cursor: 'pointer',
        },
        gray3: {
            width: '36px',
            height: '36px',
            borderRadius: '6px',
            border: 'solid 1px #525252',
            backgroundColor: '#e3e3e3',
            minWidth: 'auto',
            cursor: 'pointer',
        },
        gray4: {
            width: '36px',
            height: '36px',
            borderRadius: '6px',
            border: 'solid 1px #525252',
            backgroundColor: '#d1d1d1',
            minWidth: 'auto',
            cursor: 'pointer',
        },
        gray5: {
            width: '36px',
            height: '36px',
            borderRadius: '6px',
            border: 'solid 1px #525252',
            backgroundColor: '#bbbbbb',
            minWidth: 'auto',
            cursor: 'pointer',
        },
        gray6: {
            width: '36px',
            height: '36px',
            borderRadius: '6px',
            border: 'solid 1px #525252',
            backgroundColor: '#9f9f9f',
            minWidth: 'auto',
            cursor: 'pointer',
        },
        gray7: {
            width: '36px',
            height: '36px',
            borderRadius: '6px',
            border: 'solid 1px #525252',
            backgroundColor: '#808080',
            minWidth: 'auto',
            cursor: 'pointer',
        },
        gray8: {
            width: '36px',
            height: '36px',
            borderRadius: '6px',
            border: 'solid 1px #525252',
            backgroundColor: '#333333',
            minWidth: 'auto',
            cursor: 'pointer',
        },
    });

interface Props extends WithStyles<typeof styles> {
    skipEffect?: boolean;
}

const Component: React.FC<Props> = (props) => {
    const { classes } = props;
    const mfpSettingContainer = MfpSettingContainer.useContainer();

    const classCopy = Object.assign(classes);
    const resolutionValueList = [200, 300, 400, 600];
    const resolution = [];
    for (let i = 0; i <= 3; i += 1) {
        resolution.push(
            <Button
                key={i}
                className={i === 0 ? classes.buttonFirst : i === 3 ? classes.buttonLast : classes.buttonMiddle}
                variant="contained"
                classes={{ root: mfpSettingContainer.defaultSetting.scanAttributeData.resolution === resolutionValueList[i] ? classes.buttonSelected : undefined }}
                onClick={() => mfpSettingContainer.handleClickChooseResolution(resolutionValueList[i])}
                disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage && resolutionValueList[i] !== 300}
            >
                {resolutionValueList[i]}
                {locale.t(locale.keys.mfpDefaultSetting.switchButton.dpi)}
            </Button>,
        );
    }
    const colorType = (
        <>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            value={mfpSettingContainer.defaultSetting.scanAttributeData.colorType === undefined ? '7' : mfpSettingContainer.defaultSetting.scanAttributeData.colorType.toString()}
                            onChange={mfpSettingContainer.handleChangeTypeDocument}
                            name={'colorType'}
                        >
                            <FormControlLabel
                                className={classes.formLabel}
                                value="7"
                                control={<Radio disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage} />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.automaticColorSelection)}
                            />
                            <FormControlLabel className={classes.formLabel} value="8" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.grayscaleOcr)} />
                            <FormControlLabel
                                className={classes.formLabel}
                                value="1"
                                control={<Radio disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage} />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.blackWhiteCharactersCharts)}
                            />
                            <FormControlLabel
                                className={classes.formLabel}
                                value="3"
                                control={<Radio disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage} />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.blackWhitePhoto)}
                            />
                            <FormControlLabel className={classes.formLabel} value="6" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.fullColorPhotoPaper)} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            value={mfpSettingContainer.defaultSetting.scanAttributeData.colorType === undefined ? '7' : mfpSettingContainer.defaultSetting.scanAttributeData.colorType.toString()}
                            onChange={mfpSettingContainer.handleChangeTypeDocument}
                            name={'colorType'}
                        >
                            <FormControlLabel className={classes.formLabel} value="4" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.grayscale)} />
                            <FormControlLabel
                                className={classes.formLabel}
                                value="0"
                                control={<Radio disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage} />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.blackWhiteCharacters)}
                            />
                            <FormControlLabel
                                className={classes.formLabel}
                                value="2"
                                control={<Radio disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage} />}
                                label={locale.t(locale.keys.mfpDefaultSetting.radioButton.blackWhiteTextPhoto)}
                            />
                            <FormControlLabel className={classes.formLabel} value="5" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.fullColorTextPhoto)} />
                            <FormControlLabel className={classes.formLabel} value="9" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.fullColorOcr)} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );

    const listGray = [];
    for (let i = 2; i < 9; i += 1) {
        listGray.push(
            <Button
                key={i}
                className={classCopy[`gray${i}`]}
                style={mfpSettingContainer.defaultSetting.scanAttributeData.density === i ? { border: 'solid 4px #ff7300' } : {}}
                onClick={() => mfpSettingContainer.handleClickChooseGrayLevel(i)}
            >
                {''}
            </Button>,
        );
    }
    const eraseBorderCenter = (
        <>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup value={mfpSettingContainer.eraseBorderCenter} onChange={mfpSettingContainer.handleChangeTypeErase} name={'erase'}>
                            <FormControlLabel className={classes.formLabel} value="1" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.doNotErase)} />
                            <FormControlLabel className={classes.formLabel} value="2" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.eraseCenter)} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={6} container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup value={mfpSettingContainer.eraseBorderCenter} onChange={mfpSettingContainer.handleChangeTypeErase} name={'erase'}>
                            <FormControlLabel className={classes.formLabel} value="3" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.frameErase)} />
                            <FormControlLabel className={classes.formLabel} value="4" control={<Radio />} label={locale.t(locale.keys.mfpDefaultSetting.radioButton.frameCenterErase)} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );

    const eraseRange = (
        <>
            <Grid item xs={12} container direction="row" justify="center" alignItems="flex-start" spacing={8}>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textFieldErase}
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        label={locale.t(locale.keys.mfpDefaultSetting.title.center)}
                        value={mfpSettingContainer.defaultSetting.scanAttributeData.eraseCenterWidth}
                        onChange={mfpSettingContainer.handleChangeEraseCenterWidth}
                        onBlur={mfpSettingContainer.handleResetMinValue}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textFieldErase}
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        label={locale.t(locale.keys.mfpDefaultSetting.title.up)}
                        value={
                            mfpSettingContainer.defaultSetting.scanAttributeData.eraseSettingSame
                                ? mfpSettingContainer.defaultSetting.scanAttributeData.eraseBorderWidthSame
                                : mfpSettingContainer.defaultSetting.scanAttributeData.eraseBorderWidthTop
                        }
                        onChange={mfpSettingContainer.handleChangeEraseBorderWithTop}
                        onBlur={mfpSettingContainer.handleResetMinValue}
                    />
                </Grid>
                <Grid item xs={4}>
                    <span>{locale.t(locale.keys.mfpDefaultSetting.title.theRangeOfASetOfData)}</span>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" justify="center" alignItems="flex-start" spacing={8}>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textFieldErase}
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        label={locale.t(locale.keys.mfpDefaultSetting.title.left)}
                        value={
                            mfpSettingContainer.defaultSetting.scanAttributeData.eraseSettingSame
                                ? mfpSettingContainer.defaultSetting.scanAttributeData.eraseBorderWidthSame
                                : mfpSettingContainer.defaultSetting.scanAttributeData.eraseBorderWidthLeft
                        }
                        onChange={mfpSettingContainer.handleChangeEraseBorderWithLeft}
                        onBlur={mfpSettingContainer.handleResetMinValue}
                    />
                </Grid>
                <Grid item xs={4}>
                    <img src={eraseBorderImg.eraseBorder.fig} className={classes.eraseBorderImg} alt="img" />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textFieldErase}
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        label={locale.t(locale.keys.mfpDefaultSetting.title.right)}
                        value={
                            mfpSettingContainer.defaultSetting.scanAttributeData.eraseSettingSame
                                ? mfpSettingContainer.defaultSetting.scanAttributeData.eraseBorderWidthSame
                                : mfpSettingContainer.defaultSetting.scanAttributeData.eraseBorderWidthRight
                        }
                        onChange={mfpSettingContainer.handleChangeEraseBorderWithRight}
                        onBlur={mfpSettingContainer.handleResetMinValue}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" justify="center" alignItems="flex-start" spacing={8}>
                <Grid item xs={4}>
                    <TextField
                        className={classes.textFieldErase}
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        label={locale.t(locale.keys.mfpDefaultSetting.title.under)}
                        value={
                            mfpSettingContainer.defaultSetting.scanAttributeData.eraseSettingSame
                                ? mfpSettingContainer.defaultSetting.scanAttributeData.eraseBorderWidthSame
                                : mfpSettingContainer.defaultSetting.scanAttributeData.eraseBorderWidthBottom
                        }
                        onChange={mfpSettingContainer.handleChangeEraseBorderWithBottom}
                        onBlur={mfpSettingContainer.handleResetMinValue}
                    />
                </Grid>
            </Grid>
        </>
    );

    return (
        <>
            {mfpSettingContainer.ui === UI.state.Error && <div data-testid={UI.state.Error}>Error</div>}
            {(mfpSettingContainer.ui === UI.state.Loaded || mfpSettingContainer.ui === UI.state.Loading || props.skipEffect) && (
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item md={6} container direction="column" justify="flex-start" alignItems="flex-start" spacing={8}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.resolution)}</Typography>
                        </Grid>

                        <Grid className={classes.resolutionContent} item xs={12} container direction="row" justify="flex-start" alignItems="center">
                            {resolution}
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center">
                            <span>{locale.t(locale.keys.mfpDefaultSetting.labelSwitch.lockChange)}</span>
                            <Switch
                                className={classes.iconToggle}
                                onChange={mfpSettingContainer.handleSwitchLockChangeResolution}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={mfpSettingContainer.defaultSetting.scanAttributeData.resolutionLock}
                                disabled={mfpSettingContainer.endpoint && mfpSettingContainer.endpoint.copied && mfpSettingContainer.defaultSetting.scanAttributeData.orgResolutionLock}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.documentType)}</Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {colorType}
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center">
                            <span>{locale.t(locale.keys.mfpDefaultSetting.labelSwitch.lockChange)}</span>
                            <Switch
                                className={classes.iconToggle}
                                onChange={mfpSettingContainer.handleSwitchLockChangeDocument}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={mfpSettingContainer.defaultSetting.scanAttributeData.colorTypeLock}
                                disabled={mfpSettingContainer.endpoint && mfpSettingContainer.endpoint.copied && mfpSettingContainer.defaultSetting.scanAttributeData.orgColorTypeLock}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.grayLevel)}</Typography>
                        </Grid>

                        <Grid item xs={10} container direction="row" justify="space-between" alignItems="center">
                            {listGray}
                        </Grid>

                        <Grid item xs={11} container direction="row" justify="flex-end" alignItems="center">
                            <span>{locale.t(locale.keys.mfpDefaultSetting.labelSwitch.grayLevelAutomatically)}</span>
                            <Switch
                                onChange={mfpSettingContainer.handleSwitchAutoGrayLevel}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={mfpSettingContainer.defaultSetting.scanAttributeData.autoDensity}
                            />
                        </Grid>
                    </Grid>

                    <Grid item md={5} container direction="column" justify="flex-start" alignItems="flex-start" spacing={8}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{locale.t(locale.keys.mfpDefaultSetting.title.removingShadowsFromScannedDocuments)}</Typography>
                        </Grid>

                        <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                            {eraseBorderCenter}
                        </Grid>

                        <Grid className={classes.erase} item xs={12} container direction="column" spacing={8}>
                            {eraseRange}
                        </Grid>

                        <Grid item xs={12} container direction="column" justify="flex-start" alignItems="flex-start">
                            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    control={
                                        <Checkbox checked={mfpSettingContainer.defaultSetting.scanAttributeData.eraseSettingSame} onChange={mfpSettingContainer.handleChangeSpecifyingTheSameWidth} />
                                    }
                                    label={locale.t(locale.keys.mfpDefaultSetting.checkBox.specifyingTheSameWidth)}
                                />
                                <Button
                                    size="small"
                                    classes={{ sizeSmall: classes.buttonSingle }}
                                    variant="contained"
                                    onClick={mfpSettingContainer.handleClickRemoveEraseBorderWithSame}
                                    disabled={!mfpSettingContainer.defaultSetting.scanAttributeData.eraseSettingSame}
                                >
                                    -
                                </Button>
                                <Button
                                    size="small"
                                    classes={{ sizeSmall: classes.buttonSingle }}
                                    variant="contained"
                                    onClick={mfpSettingContainer.handleClickAddEraseBorderWithSame}
                                    disabled={!mfpSettingContainer.defaultSetting.scanAttributeData.eraseSettingSame}
                                >
                                    +
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={mfpSettingContainer.defaultSetting.scanAttributeData.omitBlankPage} onChange={mfpSettingContainer.handleChangeOmitBlankPage} />}
                                    label={locale.t(locale.keys.mfpDefaultSetting.checkBox.deleting)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
                                            onChange={mfpSettingContainer.handleChangeDoCutImage}
                                        />
                                    } label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {locale.t(locale.keys.mfpDefaultSetting.checkBox.doCutImage)}
                                            <Tooltip
                                                title={
                                                    <div>
                                                        <span style={{ fontSize: '14px' }}>
                                                            {locale.t(locale.keys.mfpDefaultSetting.description.tabThreeDoCutImage1)}
                                                        </span>
                                                        <br />
                                                        <span style={{ fontSize: '14px' }}>
                                                            {locale.t(locale.keys.mfpDefaultSetting.description.tabThreeDoCutImage2)}
                                                        </span>
                                                    </div>
                                                }
                                                placement="right"
                                            >
                                                <Help style={{ marginLeft: "8px" }} fontSize="small" color="secondary" />
                                            </Tooltip>
                                        </div>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={mfpSettingContainer.defaultSetting.scanAttributeData.verticalJudge}
                                            onChange={mfpSettingContainer.handleChangeVerticalJudge}
                                            disabled={mfpSettingContainer.defaultSetting.scanAttributeData.doCutImage}
                                        />
                                    } label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {locale.t(locale.keys.mfpDefaultSetting.checkBox.verticalJudge)}
                                        <Tooltip
                                            title={
                                                <div>
                                                    <span style={{ fontSize: '14px' }}>
                                                        {locale.t(locale.keys.mfpDefaultSetting.description.tabThreeVerticalJudge1)}
                                                    </span>
                                                    <br />
                                                    <span style={{ fontSize: '14px' }}>
                                                        {locale.t(locale.keys.mfpDefaultSetting.description.tabThreeVerticalJudge2)}
                                                    </span>
                                                </div>
                                            }
                                            placement="right"
                                        >
                                            <Help style={{marginLeft: "8px"}} fontSize="small" color="secondary" />
                                        </Tooltip>
                                    </div>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <DialogConfirmChanges callBack={mfpSettingContainer.dialogObject.functionHandleConfirmChanges} />
                </Grid>
            )}
        </>
    );
};

export default withStyles(styles)(Component);
