import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import { csvFormat } from '@/common/constants/csvFormat';
import { EmailValidate } from '@/common/constants/email';

export interface Form {
    terms: boolean;
    name: string;
    phoneticName: string;
    workspaceName: string;
    adminName: string;
    mail: string;
    userLang: string;
    contactEmail: string;

    password: string;
    showPassword: boolean;
    passwordConfirm: string;
    showPasswordConfirm: boolean;

    validateInit: {
        name: boolean;
        phoneticName: boolean;
        terms: boolean;
        userLang: boolean;
        deviceLoginUser: boolean;
        pin: boolean;
        contactEmail: boolean;
    };

    passwordRecoveryValidateInit: {
        password: boolean;
        passwordConfirm: boolean;
    };
    displayId: string;
    workspaceId: string;
    token: string;
    active: boolean;
    role: number;
    enableSubWorkspace: boolean;
    logoUrl: string;
    avatarUrl: string;
    deviceLoginUser: string;
    pin: string;
    errors: validator.ValidationOutput;
}

export enum MemberSignupState {
    MemberSignup = 'memberSignup',
    PasswordSetting = 'passwordSetting',
}

export const New = (): Form => ({
    terms: false,
    name: '',
    phoneticName: '',
    workspaceName: '',
    adminName: '',
    mail: '',
    userLang: '0',
    contactEmail: '',

    password: '',
    showPassword: false,
    passwordConfirm: '',
    showPasswordConfirm: false,

    validateInit: {
        name: false,
        phoneticName: false,
        terms: false,
        userLang: false,
        deviceLoginUser: false,
        pin: false,
        contactEmail: false,
    },
    passwordRecoveryValidateInit: {
        password: false,
        passwordConfirm: false,
    },
    displayId: '',
    workspaceId: '',
    token: '',
    active: false,
    role: 0,
    enableSubWorkspace: false,
    logoUrl: '',
    avatarUrl: '',
    deviceLoginUser: '',
    pin: '',
    // Initialize error holder.
    errors: NewValidation(),
});

export const validations = (): validator.Constraints => {
    return {
        name: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        phoneticName: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        userLang: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        terms: {
            inclusion: {
                within: [true],
                message: locale.t(locale.keys.validation.required),
            },
        },
        deviceLoginUser: {
            length: {
                maximum: csvFormat.DEVICELOGINUSER_MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: csvFormat.DEVICELOGINUSER_MAX_LENGTH }),
            },
            format: {
                pattern: csvFormat.DEVICELOGINUSER_OPTIONAL,
                flags: 'g', // 正規表現の検索オプション
                message: locale.t(locale.keys.validation.deviceLoginUser),
            },
        },
        pin: {
            pinPolicy: {
                message: locale.t(locale.keys.validation.pinPolicy),
            },
        },
        contactEmail: {
            userEmail: {
                allowEmpty: true,
                allowPlus: true,
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        name: null,
        phoneticName: null,
        terms: null,
        userLang: null,
        deviceLoginUser: null,
        pin: null,
        contactEmail: null,
    };
};

export const passwordRecoveryValidations = (): validator.Constraints => {
    return {
        password: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            passwordPolicy: {
                message: locale.t(locale.keys.validation.passwordPolicy),
            },
        },
        passwordConfirm: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
            equality: {
                attribute: 'password',
                message: locale.t(locale.keys.validation.notEnough),
            },
        },
    };
};

export const NewPasswordRecoveryValidation = (): validator.ValidationOutput => {
    return {
        password: null,
        passwordConfirm: null,
    };
};
