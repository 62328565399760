import locale from '@/common/utils/locale';
import * as validator from '@/common/utils/validator';
import * as schema from '@/bundles/schema/typescript/schema';
import * as workspaceuser from '@/user/models/workspaceuser';
import { State as AppState } from '@/common/components/AppContainerBase';
import userRole from '@/common/constants/userRole';
import { csvFormat } from '@/common/constants/csvFormat';
import { EmailValidate } from '@/common/constants/email';

// find user profile
export const findUserProfile = async (workspaceId: string, userId: string, auth: string): Promise<schema.V1ObjectsWorkspaceuserLarge> => {
    const response = await workspaceuser.showWorkspaceUser(workspaceId, userId, auth);
    return response.user!;
};

// update profile
export const updateUserProfile = async (req: schema.V1WorkspaceuserUpdateRequest, userId: string, auth: string): Promise<schema.V1WorkspaceuserUpdateResponse> => {
    const response = await workspaceuser.updateUser(userId, auth, req);
    return response;
};

export interface Form {
    avatarUrl: string;
    name: string;
    phoneticName: string;
    email: string;
    deviceLoginUser: string;
    language: schema.Language;
    contactEmail: string;
    active: boolean;
    role: number;
    init: boolean;
    notifications: schema.V1ObjectsWorkspaceusersEmailNotification;
    pin: string;
    errors: validator.ValidationOutput;
}

export const New = (state: AppState): Form => ({
    avatarUrl: state.signinWorkspaceUserObject != null && state.signinWorkspaceUserObject.avatarUrl != null ? state.signinWorkspaceUserObject.avatarUrl! : '',
    name: state.signinWorkspaceUserObject != null && state.signinWorkspaceUserObject.name != null ? state.signinWorkspaceUserObject.name! : '',
    phoneticName: state.signinWorkspaceUserObject != null && state.signinWorkspaceUserObject.phoneticName != null ? state.signinWorkspaceUserObject.phoneticName! : '',
    email: state.signinWorkspaceUserObject != null && !state.signinWorkspaceUserObject.invitationEmail != null ? state.signinWorkspaceUserObject.invitationEmail! : '',
    deviceLoginUser: state.signinWorkspaceUserObject != null && !state.signinWorkspaceUserObject.deviceLoginUser != null ? state.signinWorkspaceUserObject.deviceLoginUser! : '',
    role: state.signinWorkspaceUserObject != null ? state.signinWorkspaceUserObject.role : userRole.member,
    init: false,
    language: state.signinWorkspaceUserObject != null ? state.signinWorkspaceUserObject.language : schema.Language.En,
    contactEmail: state.signinWorkspaceUserObject != null && !state.signinWorkspaceUserObject.contactEmail != null ? state.signinWorkspaceUserObject.contactEmail! : '',
    active: state.signinWorkspaceUserObject != null ? state.signinWorkspaceUserObject.active : true,
    notifications: {
        serviceError: false,
        sharingEvent: false,
        supportMessage: false,
    },
    pin: state.signinWorkspaceUserObject != null && !state.signinWorkspaceUserObject.pin != null ? state.signinWorkspaceUserObject.pin! : '',
    // Initialize error holder.
    errors: NewValidation(),
});

export const validations = (): validator.Constraints => {
    return {
        name: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        phoneticName: {
            presence: {
                allowEmpty: false,
                message: locale.t(locale.keys.validation.required),
            },
        },
        deviceLoginUser: {
            length: {
                maximum: csvFormat.DEVICELOGINUSER_MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: csvFormat.DEVICELOGINUSER_MAX_LENGTH }),
            },
            format: {
                pattern: csvFormat.DEVICELOGINUSER_OPTIONAL,
                flags: 'g', // 正規表現の検索オプション
                message: locale.t(locale.keys.validation.deviceLoginUser),
            },
        },
        contactEmail: {
            userEmail: {
                allowEmpty: true,
                allowPlus: true,
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewValidation = (): validator.ValidationOutput => {
    return {
        name: null,
        phoneticName: null,
        deviceLoginUser: null,
        contactEmail: null,
    };
};
